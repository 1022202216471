import axios from "axios"

const api = "auth"

const auth = {
    pedirPassword: data => axios.post(`${api}/pedir-password`, data),
    login(payload) {
        return axios.post(`${api}/login`,payload)
    },
    validarSesion(){
        return axios.get(`${api}/validar-token`)
    }
}

export default auth