import axios from 'axios'
import store from '../store'
import router from '../router'
import {Notification} from 'element-ui'

axios.defaults.baseURL = process.env.VUE_APP_URL || 'http://hunters.localhost/api'

// Request interceptor
axios.interceptors.request.use(request => {
    const token = localStorage.getItem('token');
    if (token) request.headers.common['Authorization'] = `Bearer ${token}`

    const locale = store.getters['lang/locale']
    if (locale) request.headers.common['Accept-Language'] = locale

    // request.headers['X-Socket-Id'] = Echo.socketId()
    return request
})

// Response interceptor
axios.interceptors.response.use(response => response, error => {
    const { status } = error.response

    if (status >= 500) console.error(error)

    if (status === 401){
        Notification({
            title: "Sesión finalizada",
            message: "vuelve a iniciar sesión nuevamente para continuar",
            type: "warning",
            dangerouslyUseHTMLString: false
        })
        router.push({ name: 'home.desafios-disponibles' })
    }

    return Promise.reject(error)
})
