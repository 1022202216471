import Vue from 'vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

import './axios'
import './element'
// import './moment'

import Vue2Filters from "vue2-filters";
Vue.use(Vue2Filters)
